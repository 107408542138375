<template>
    <div style="margin-bottom:2rem;">
        <div class="title1">
            <p class="title_top">LAWS AND REGULATIONS</p>
            <p class="title_content">法律法规</p>
        </div>
        <div class="flex wrap">
            <div class="col3" style="height:500px;" @click="go(index+1)" v-for="(item,index) in data" :key="index">
                <Item :data="item" ></Item>
            </div>

        </div>
        <More></More>
    </div>
</template>
<script>
import Item from "@/components/columnItem.vue"
import More from "@/components/More.vue"
export default {
    components:{Item,More},
    data(){
        return{
            data:[
                {url:'news/law/1.png',title:'《司法鉴定标准化工作指南》',time:'2021-12-07',content:`为规范全国司法行政信息化建设，加强对全国司法行政业务工作的指导，司法部制定了《司法鉴定标准化工作指南》等19项行业标准，修订了《法医临床检验规范》等16项行业标准。`},
                {url:'news/law/2.png',title:'司法鉴定程序通则',time:'2016年3月2日',content:`(2016年3月2日司法部令第132号发布,自2016年5月1日起施行)`},
                {url:'news/law/3.jpg',title:'中华人民共和国民法典',time:'2020-06-10 ',content:`《中华人民共和国民法典》已由中华人民共和国第十三届全国人民代表大会第三次会议于2020年5月28日通过，现予公布，自2021年1月1日起施行。`},
                
                ]
        }
    },
    methods:{
        go(path){
            let pathStr = '/news/law/'+path
            this.$store.commit('set_path',{path:pathStr,query:{}})
        }
    }
}
</script>